<template>
  <!--
    The view for the ProblemReport-component.
    Used to report a problem.
  -->
  <Portlet 
    :title="$t('reportView.report')" 
    icon="bullhorn" 
    class="report"
  >
    <ProblemReport />
  </Portlet>
</template>

<script>
export default {
  name: "Report",
  components: {
    ProblemReport: () => import('@/components/Problem/ProblemReport.vue')
  },
  metaInfo () {
    return {
      title: this.$t('reportProblem')
    }
  }
}
</script>